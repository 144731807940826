import { put, call, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import CONFIG from "config";
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { RESPONSE_DATA, ROLES, DOCVIZ, TAXONOMY } from "redux/constants";
import { getSLparentTagsData } from "redux/actions/taxonomy/Taxonomy.saga";
import authService from 'redux/auth';
import { getUserId } from "utils/auth/auth";
import axios from 'axios';

import {
    getResponseLoading,
    getResponseFailure,
    setFilters,
    getRetrievalSuccess,
    getFileDownloadSuccess,
    getFilesDownloadFailure,
    getFilesDownloadPending,
    updateTranscriptInQueryResults,
    updateTranscriptSelected,
    selectTranscript,
    companyFilterApplied,
    setMonthOfInterviewRangeValue,
    getTranscriptDetail,
    internalTagsAPILoading,
    internalTagsAPIFailure,
    internalTagsAPISuccess,
    setScrollLoading,
    setIsLastPage,
    setBookmarkPending,
    setBookmarkFailure,
    getAllBookmarksSuccess,
    getAllKeywordSearchHistorySuccess,
    getChatSearchHistorySuccess,
    chatAddMessage
} from "./response.actions";

import {
    updateDocvizMetadataDoc,
} from "../docviz/docviz.actions";

import {
    notifySuccess
} from "../notification/notification_actions";

// helper
import {
    getMonthYearFromDate,
    downloadFileFromUrl,
    getRandomString
} from "utils/helpers/helpers";
window.Buffer = window.Buffer || require("buffer").Buffer;

const {
    QUERY_RESPONSE,
    GET_FILE,
    GET_SELECTED_TRANSCRIPT,
    GET_DOCVIZ_REQUEST_IDS,
    GET_DOWNLOAD_COUNT,
    GET_TRANSCRIPT_DETAIL,
    QUERY_CHAT_API,
    CHAT_PENDING,
    SET_LATEST_SEARCH_TEXT,
    CHAT_DELETE_MESSAGE,
    CHAT_STREAM_NEW_MESSAGE,
    CHAT_STREAM_CHUNK,
    UPDATE_TRANSCRIPT_IN_QUERY_RESULTS,
    CHAT_SUCCESS,
    CHAT_SET_HISTORY_ID,
    CHAT_FAILURE,
    SET_FETCH_CONTROLLER,
    SELECT_TRANSCRIPT,
    SET_BOOKMARK,
    DELETE_BOOKMARK,
    DELETE_ALL_BOOKMARK,
    GET_ALL_BOOKMARK,
    DELETE_KEYWORD_SEARCH_HISTORY,
    GET_ALL_KEYWORD_SEARCH_HISTORY,
    DELETE_CHAT_SEARCH_HISTORY,
    GET_CHAT_SEARCH_HISTORY,
    GET_CHAT_SEARCH_HISTORY_BY_ID,
    CHAT_ADD_MESSAGE,
    SEARCH_BOOKMARK
} = RESPONSE_DATA
const { UPDATE_DOCVIZ_METADATA_DOC } = DOCVIZ

const {
    TBDB_IDS: {
        SEARCH_SUBJECT,
        FPA,
        IPA,
        BCG_INTERNAL
    }
} = CONFIG;

export function* getQueryResonse(action) {
    const { payload: {
        question,
        filters = {},
        resetFilters = false,
        currentPage,
        transcriptResults = [],
        dispatch,
        pageReload
    } } = action;
    try {
        const { NUM_OF_RESULTS, API_URL: { RETRIVAL_API, SAVE_KEYWORD_HISTORY_API }, ENRICH_API_DOMAIN_KEY, API_KEYS: { SMP_API_KEY, SMP_GENAI_API_KEY }, TL_DOCVIZ_S3_BUCKET_NAME, TL_VOYAGER_CONSUMER_KEY, TL_VOYAGER_DATA_SOURCE, TL_VOYAGER_WORKSPACE_ID } = CONFIG;
        const requestId = getRandomString(20);
        yield put(getResponseLoading());
        // Start Save Keyword Search
        if (pageReload) {
            const hrid = yield call(getUserId);
            const khRequest = {
                method: 'POST',
                endpoint: SAVE_KEYWORD_HISTORY_API,
                headers: {
                    'x-api-key': SMP_API_KEY
                },
                params: {
                    "hrId": hrid,
                    "searchTerm": question
                }
            };
            yield call(doAxiosRequest, khRequest);
        }
        // End Save Keyword Search
        if (currentPage !== 0) { yield put(setScrollLoading()); }
        let postData = {
            method: 'post',
            endpoint: RETRIVAL_API,
            headers: {
                'x-api-key': SMP_GENAI_API_KEY
            },
            params: {
                "query": question,
                "method": "hybrid_1",
                "top_n": 200,
                "consumer_options": {
                    "consumer_key": "EET",
                    "vector_store_url_param": "eet",
                    "data_source": "eet"
                },
                "top_vector_per_doc": true
            }
        }
        const companyList = [];

        yield put(setMonthOfInterviewRangeValue(""));
        const res = yield call(doAxiosRequest, postData);
        if (res.results.length === 0) {
            yield put(setIsLastPage(true));
        }
        res.results = [...transcriptResults, ...res.results];
        //res.results = [...new Map(res.results.map(item => [item["meeting_id"], item])).values()];
        yield put(getRetrievalSuccess(res.results));
        const filteredIndustryTags = []
        const filteredFunctionTags = []
        let filters = {
            filteredFunctionTags,
            filteredIndustryTags
        };
        yield put(setFilters(filters));
        const transcriptIds = res.results.map(i => i.meeting_id);
        const docvizRequestIDAPIResponse = yield call(callGetDocvizRequestIDAPI, transcriptIds);
        yield res.results.forEach(async (transcript, index) => {
            const filteredDocvizRequestId = docvizRequestIDAPIResponse?.docvizStatusList
                .filter(i => (i.meetingId === transcript.meeting_id && i.conversionStatus === "COMPLETED"));
            if (filteredDocvizRequestId.length > 0) {
                transcript.docvizRequestId = filteredDocvizRequestId[0].requestId;
                if (index === 0 && currentPage === 0) {
                    dispatch({ type: SELECT_TRANSCRIPT, payload: { response: transcript } });
                    if (transcript.industryTags?.length > 0) {
                        dispatch({
                            type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: {
                                ids: transcript.industryTags, key: IPA
                            }
                        })
                    }
                    if (transcript.functionalTags?.length > 0) {
                        dispatch({
                            type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: {
                                ids: transcript.functionalTags, key: FPA
                            }
                        })
                    }
                }
                const newSource = axios.CancelToken.source();
                try {
                    const clientToken = await authService.getAccessToken();
                    const response = await axios.get(`https://${TL_DOCVIZ_S3_BUCKET_NAME}.bcg.com/${transcript.meeting_id}/${transcript.docvizRequestId}/images/medium/png/Page-1.png`,
                        {
                            headers: {
                                authorization: `Bearer ${clientToken}`,
                                'x-api-key': ENRICH_API_DOMAIN_KEY
                            },
                            responseType: 'arraybuffer', cancelToken: newSource.token
                        });

                    const data = response.data || response;
                    const imageData = `data:image/png;base64,${Buffer.from(data, 'binary').toString('base64')}`;
                    transcript.imageData = imageData;
                    transcript.docvizInProcess = false;
                    transcript.docvizConversionStatus = "COMPLETED";
                    dispatch({ type: UPDATE_TRANSCRIPT_IN_QUERY_RESULTS, payload: { response: transcript } });

                } catch (error) {
                    console.log(error);
                }
            } else {
                transcript.docvizInProcess = false;
                transcript.docvizConversionStatus = "NOT_COMPLETED";
                dispatch({ type: UPDATE_TRANSCRIPT_IN_QUERY_RESULTS, payload: { response: transcript } });
            }
        });
        yield put(companyFilterApplied(companyList.length));

        if (res.filters) {
            if (resetFilters || companyList.length === 0) {
                let companies = res.filters.filter(i => i["company"])[0]?.company || [];
                companies = companies.filter(i => !i.includes("Not Available"));
                companies = companies.map(company => ({ "key": company, "value": company, "selected": false, "show": true, "filterShow": true }))
                let groupedCompanies = companies.sort((a, b) => a.value.localeCompare(b.value)).reduce((r, e) => {
                    // get first letter of name of current element
                    let group = e.value[0].toUpperCase();
                    // if there is no property in accumulator with this letter create it
                    if (!r[group]) r[group] = { group, children: [e] }
                    // if there is push current element to children array for that letter
                    else r[group].children.push(e);
                    // return accumulator
                    return r;
                }, {})
                groupedCompanies = Object.keys(groupedCompanies).map(i => groupedCompanies[i]);
                const filters = {
                    groupedCompanies,
                };

                if (resetFilters) {
                    filters.monthOfInterviewRange = {
                        startMonth: null,
                        endMonth: null
                    };
                    filters.enabledMonths = [...new Set(res.results.map(i => new Date(i.date_of_interview * 1000).getMonth() + "" + new Date(i.date_of_interview * 1000).getFullYear()))];
                    filters.initialYear = filters.enabledMonths.map(i => i.substring(i.length === 5 ? 1 : 2)).sort()[0];

                    const sources = {};
                    res.results.forEach(transcript => {
                        if (sources[transcript.vendor_source]) {
                            sources[transcript.vendor_source].push(transcript.meeting_id);
                        } else {
                            sources[transcript.vendor_source] = [transcript.meeting_id];
                        }
                    });

                    const sourceList = Object.keys(sources);
                    filters.sources = sourceList.map(i => ({
                        "key": i,
                        "value": i + " (" + sources[i].length + ")",
                        "selected": false,
                        "show": sources[i].length > 0,
                        "transcriptIds": sources[i]
                    }));
                }
                yield put(setFilters(filters));
            }
            const taggingInternalAPIrequest = res.results.filter(i => i.industryTags?.length > 0 || i.functionalTags?.length > 0)
                .map(i => {
                    let dummyRequest = {
                        transcriptId: i.meeting_id,
                        tags: []
                    }
                    if (i.industryTags?.length > 0) {
                        dummyRequest.tags.push({
                            practiceName: "IndustryPracticeArea0",
                            tags: i.industryTags
                        });
                    }
                    if (i.functionalTags?.length > 0) {
                        dummyRequest.tags.push({
                            practiceName: "FunctionalPracticeArea",
                            tags: i.functionalTags
                        });
                    }
                    return dummyRequest;
                });
            const taggingInternalAPIResponse = yield call(callTaggingInternalAPI, taggingInternalAPIrequest);
            const industryTags = taggingInternalAPIResponse.filter(i => i.name === "Industry Practice Area");

            if (industryTags.length > 0) {
                industryTags[0].tree.forEach((i, k) => {
                    if (i.count > 0) {
                        const dummy = {
                            tagName: i.name,
                            count: i.count,
                            transcriptIds: i.transcriptIds,
                            selected: k === 0,
                            checked: false,
                            filterShow: true,
                            visible: true
                        }
                        if (i?.tree && i.tree.length > 0) {
                            dummy.children = i.tree.filter(j => j.count > 0).map(j => {
                                if (j?.tree && j.tree.length > 0) {
                                    return {
                                        tagName: j.name,
                                        count: j.count,
                                        transcriptIds: j.transcriptIds,
                                        selected: false,
                                        checked: false,
                                        filterShow: true,
                                        visible: true,
                                        children: j.tree.filter(k => k.count > 0).map(k => {
                                            return {
                                                tagName: k.name,
                                                count: k.count,
                                                transcriptIds: k.transcriptIds,
                                                selected: false,
                                                checked: false,
                                                filterShow: true,
                                                visible: true
                                            }
                                        })
                                    }
                                }
                                return {
                                    tagName: j.name,
                                    count: j.count,
                                    transcriptIds: j.transcriptIds,
                                    selected: false,
                                    checked: false,
                                    filterShow: true,
                                    visible: true
                                }
                            })
                        }
                        filteredIndustryTags.push(dummy);
                    }
                })
            }
            const functionTags = taggingInternalAPIResponse.filter(i => i.name === "Functional Practice Area");

            if (functionTags.length > 0) {
                functionTags[0].tree.forEach((i, k) => {
                    if (i.count > 0) {
                        const dummy = {
                            tagName: i.name,
                            count: i.count,
                            transcriptIds: i.transcriptIds,
                            selected: k === 0,
                            checked: false,
                            filterShow: true,
                            visible: true
                        }
                        if (i?.tree && i.tree.length > 0) {
                            dummy.children = i.tree.filter(j => j.count > 0).map(j => {
                                if (j?.tree && j.tree.length > 0) {
                                    return {
                                        tagName: j.name,
                                        count: j.count,
                                        transcriptIds: j.transcriptIds,
                                        selected: false,
                                        checked: false,
                                        filterShow: true,
                                        visible: true,
                                        children: j.tree.filter(k => k.count > 0).map(k => {
                                            return {
                                                tagName: k.name,
                                                count: k.count,
                                                transcriptIds: k.transcriptIds,
                                                selected: false,
                                                checked: false,
                                                filterShow: true,
                                                visible: true
                                            }
                                        })
                                    }
                                }
                                return {
                                    tagName: j.name,
                                    count: j.count,
                                    transcriptIds: j.transcriptIds,
                                    selected: false,
                                    checked: false,
                                    filterShow: true,
                                    visible: true
                                }
                            })
                        }
                        filteredFunctionTags.push(dummy);
                    }
                })
            }

            filters = {
                filteredFunctionTags,
                filteredIndustryTags
            };
            yield put(setFilters(filters));

        }
    } catch (error) {
        console.error("error: ", error)
        yield put(getResponseFailure(error?.message));
    }
}

export function* getSelectedTranscript(action) {
    const { payload: {
        transcriptId
    } } = action;
    try {
        const { API_URL: { RETRIVAL_API }, API_KEYS: { SMP_API_KEY, SMP_GENAI_API_KEY }, TL_VOYAGER_CONSUMER_KEY, TL_VOYAGER_DATA_SOURCE, TL_VOYAGER_WORKSPACE_ID } = CONFIG;
        yield put(getResponseLoading());
        const requestId = getRandomString(20);
        const res = yield call(doAxiosRequest, {
            method: 'post',
            endpoint: RETRIVAL_API,
            headers: {
                'x-api-key': SMP_GENAI_API_KEY
            },
            params: {
                "query": " a",
                "method": "vector",
                "top_n": 200,
                "meeting_id": [
                    transcriptId
                ],
                "consumer_options": {
                    "consumer_key": "EET",
                    "vector_store_url_param": "eet",
                    "data_source": "eet"
                },
                "top_vector_per_doc": true
            }
        });
        if (res?.results?.length > 0) {
            //res.results = [...new Map(res.results.map(item => [item["meeting_id"], item])).values()];
            yield put(selectTranscript(res.results[0]));
            if (res.results[0].industryTags?.length > 0) {
                yield call(getSLparentTagsData, {
                    payload: {
                        ids: res.results[0].industryTags, key: IPA
                    }
                });
            }
            if (res.results[0].functionalTags?.length > 0) {
                yield call(getSLparentTagsData, {
                    payload: {
                        ids: res.results[0].functionalTags, key: FPA
                    }
                });
            }
            yield put(updateDocvizMetadataDoc({ fileName: res.results[0].sanitized_attachment_name.replace(/(\r\n|\n|\r)/gm, "") + ".docx" }));
        }
    } catch (error) {
        console.error("error: ", error)
        yield put(getResponseFailure(error?.message));
    }
}

function* callGetDocvizRequestIDAPI(transcriptIds) {
    try {
        const { API_URL: { INTERNAL_API }, X_API_KEY } = CONFIG;
        const res = yield call(doAxiosRequest, {
            method: 'post',
            endpoint: INTERNAL_API,
            headers: {
                'x-api-key': X_API_KEY
            },
            params: {
                "meetingList": transcriptIds
            }
        });
        return res;
    } catch (error) {
        console.error("error: ", error);
    }
}

function* callTaggingInternalAPI(taggingInternalAPIrequest) {
    try {
        const { API_URL: { TAGGING_INTERNAL_API }, X_API_KEY } = CONFIG;
        yield put(internalTagsAPILoading());
        const res = yield call(doAxiosRequest, {
            method: 'post',
            endpoint: TAGGING_INTERNAL_API,
            headers: {
                'x-api-key': X_API_KEY
            },
            params: taggingInternalAPIrequest
        });
        yield put(internalTagsAPISuccess());
        return res;
    } catch (error) {
        yield put(internalTagsAPIFailure());
        console.error("error: ", error);
    }
}

export function* getDocvizRequestIds(action) {
    const { payload: {
        transcriptIds
    } } = action;
    try {
        const res = yield call(callGetDocvizRequestIDAPI, transcriptIds);
        if (res?.docvizStatusList[0]?.requestId && res?.docvizStatusList[0]?.conversionStatus === "COMPLETED") {
            yield put(updateTranscriptSelected({ docvizRequestId: res?.docvizStatusList[0]?.requestId, meeting_id: transcriptIds[0] }));
        }
    } catch (error) {
        console.error("error: ", error)
    }
}

export function* getDownloadCount(action) {

    try {
        const { payload: {
            transcriptId
        } } = action;
        const { API_KEYS: { SMP_API_KEY }, API_URL: { GET_DOWNLOAD_COUNT_API } } = CONFIG;
        const axiosConfig = {
            method: 'GET',
            endpoint: GET_DOWNLOAD_COUNT_API(transcriptId),
            headers: {
                'x-api-key': SMP_API_KEY
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response?.downloadCountList[0]?.logicalDownloadAllTime) {
            yield put(updateTranscriptSelected({ downloadCount: response?.downloadCountList[0]?.logicalDownloadAllTime }));
        }
    } catch (error) {
        console.error("error: ", error)
    }
}

export function* getTranscriptDetailFromDB(action) {

    try {
        const { payload: {
            transcriptId
        } } = action;
        const { API_KEYS: { SMP_API_KEY }, API_URL: { GET_TRANSCRIPT_DETAIL_API } } = CONFIG;
        const hrid = yield call(getUserId);
        const axiosConfig = {
            method: 'GET',
            endpoint: GET_TRANSCRIPT_DETAIL_API(transcriptId, hrid),
            headers: {
                'x-api-key': SMP_API_KEY
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);

        if (response) {
            const tempObj = {};
            tempObj.published_date = new Date(response?.publishedDate).getTime() / 1000;
            tempObj.duration_of_interview = response?.interviewDuration;
            tempObj.expert_biography = response?.experts[0].sanitized_biography || "-";
            tempObj.expert_employment_history = response?.experts[0].employments
                .map(ee => ({
                    "company": ee.company,
                    "employment_end_date": "",
                    "employment_start_date": ee.startDate,
                    "sanitized_position": ee.sanitizedPosition,
                    "tenure": ee.tenure,
                }));
            let inferredDiscussionTopics = response?.inferredDiscussionTopics;
            if (typeof inferredDiscussionTopics === 'string') {
                inferredDiscussionTopics = JSON.parse(inferredDiscussionTopics);
            }
            tempObj.inferredDiscussionTopics = inferredDiscussionTopics;
            let targetDiscussionTopics = response?.targetDiscussionTopics;
            if (typeof targetDiscussionTopics === 'string') {
                targetDiscussionTopics = JSON.parse(targetDiscussionTopics);
            }
            tempObj.targetDiscussionTopics = targetDiscussionTopics;
            tempObj.primaryTopicName = response?.primaryTopicName;
            tempObj.bookmarked = response?.bookmarked;
            yield put(updateTranscriptSelected(tempObj));
        }
    } catch (error) {
        console.error("error: ", error)
    }
}

export function* getFile(action) {
    try {
        yield put(getFilesDownloadPending());
        const { payload: { transcript: { kpId, fileName } } } = action;
        const { API_KEYS: { SMP_API_KEY }, API_URL: { GET_FILE_API }, TL_SEARCHABLE_S3_BUCKET_NAME } = CONFIG;
        const axiosConfig = {
            method: 'GET',
            endpoint: GET_FILE_API(kpId, `${fileName}`, `${TL_SEARCHABLE_S3_BUCKET_NAME}`, `${kpId}.docx`),
            headers: {
                'x-api-key': SMP_API_KEY
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);

        downloadFileFromUrl(response?.documentData?.preSignedUrl);
        yield put(getFileDownloadSuccess());

    } catch (error) {
        yield put(getFilesDownloadFailure(error));
    }
};

export function* setBookmark(action) {
    try {
        yield put(setBookmarkPending());
        const { payload: { transcript: { meeting_id, generated_title } } } = action;
        const { API_KEYS: { SMP_API_KEY }, API_URL: { SET_BOOKMARK_API } } = CONFIG;
        const hrid = yield call(getUserId);
        const request = {
            method: 'POST',
            endpoint: SET_BOOKMARK_API,
            headers: {
                'x-api-key': SMP_API_KEY
            },
            params: {
                "hrId": hrid,
                "meetingId": meeting_id,
                "keyword": generated_title
            }
        };
        const bookmarkResponse = yield call(doAxiosRequest, request);
        if (bookmarkResponse === "Bookmark saved successfully!") {
            yield put(updateTranscriptSelected({ bookmarked: true }));
            yield put(notifySuccess("success", "Added to your bookmarks."));
        }

    } catch (error) {
        yield put(setBookmarkFailure(error));
    }
};

export function* deleteBookmark(action) {
    try {
        yield put(setBookmarkPending());
        const { payload: { transcript: { meeting_id, meetingId }, bookmarkedTranscripts, selectedTrancript } } = action;
        const { API_KEYS: { SMP_API_KEY }, API_URL: { DELETE_BOOKMARK_API } } = CONFIG;
        const hrid = yield call(getUserId);
        const request = {
            method: 'DELETE',
            endpoint: DELETE_BOOKMARK_API(hrid, meeting_id || meetingId),
            headers: {
                'x-api-key': SMP_API_KEY
            }
        };
        const bookmarkResponse = yield call(doAxiosRequest, request);
        if (bookmarkResponse === "Bookmark deleted successfully!") {
            if (selectedTrancript) {
                if (selectedTrancript !== '' && selectedTrancript.meeting_id === meetingId) {
                    yield put(updateTranscriptSelected({ bookmarked: false }));
                }
            } else {
                yield put(updateTranscriptSelected({ bookmarked: false }));
            }

            if (bookmarkedTranscripts && bookmarkedTranscripts.length > 0) {
                const newBookMarkedTranscripts = bookmarkedTranscripts.filter((bt) => bt.meetingId !== meetingId);
                yield put(getAllBookmarksSuccess(newBookMarkedTranscripts));
            }
            yield put(notifySuccess("success", "Removed from your bookmarks."));
        }

    } catch (error) {
        yield put(setBookmarkFailure(error));
    }
};

export function* getBookmarks() {
    try {
        yield put(setBookmarkPending());
        const { API_KEYS: { SMP_API_KEY }, API_URL: { GET_BOOKMARK_API } } = CONFIG;
        const hrid = yield call(getUserId);
        const request = {
            method: 'GET',
            endpoint: GET_BOOKMARK_API(hrid),
            headers: {
                'x-api-key': SMP_API_KEY
            }
        };
        const bookmarkResponse = yield call(doAxiosRequest, request);
        if (bookmarkResponse && bookmarkResponse.length > 0) {
            yield put(getAllBookmarksSuccess(bookmarkResponse));
        } else {
            yield put(getAllBookmarksSuccess([]));
        }
    } catch (error) {
        yield put(setBookmarkFailure(error));
    }
};

export function* searchBookmarks(action) {
    try {
        yield put(setBookmarkPending());
        const { API_KEYS: { SMP_API_KEY }, API_URL: { GET_BOOKMARK_API } } = CONFIG;
        const { payload: { searchBookmark } } = action;
        const hrid = yield call(getUserId);
        const request = {
            method: 'POST',
            endpoint: GET_BOOKMARK_API(hrid) + '/search',
            headers: {
                'x-api-key': SMP_API_KEY
            },
            params: {
                "keyword": searchBookmark
            }
        };
        const bookmarkResponse = yield call(doAxiosRequest, request);
        if (bookmarkResponse && bookmarkResponse.length > 0) {
            yield put(getAllBookmarksSuccess(bookmarkResponse));
        } else {
            yield put(getAllBookmarksSuccess([]));
        }
    } catch (error) {
        yield put(setBookmarkFailure(error));
    }
};

export function* getKeywordSearchHistory() {
    try {
        const { API_KEYS: { SMP_API_KEY }, API_URL: { GET_KEYWORD_HISTORY_API } } = CONFIG;
        const hrid = yield call(getUserId);
        const request = {
            method: 'GET',
            endpoint: GET_KEYWORD_HISTORY_API(hrid),
            headers: {
                'x-api-key': SMP_API_KEY
            }
        };
        const kshResponse = yield call(doAxiosRequest, request);
        if (kshResponse && kshResponse.length > 0) {
            yield put(getAllKeywordSearchHistorySuccess(kshResponse));
        } else {
            yield put(getAllKeywordSearchHistorySuccess([]));
        }
    } catch (error) {
        console.log("ksh error", error);
    }
};

export function* deleteKeyworSearchHistory(action) {
    try {
        yield put(setBookmarkPending());
        const { payload: { kh: { id }, keywordHistory } } = action;
        const { API_KEYS: { SMP_API_KEY }, API_URL: { DELETE_KEYWORD_HISTORY_API } } = CONFIG;
        const hrid = yield call(getUserId);
        const request = {
            method: 'DELETE',
            endpoint: DELETE_KEYWORD_HISTORY_API(hrid, id),
            headers: {
                'x-api-key': SMP_API_KEY
            }
        };
        const khResponse = yield call(doAxiosRequest, request);
        if (khResponse === "History Keyword deleted successfully!") {
            if (keywordHistory && keywordHistory.length > 0) {
                const newkeywordHistory = keywordHistory.filter((kh) => kh.id !== id);
                yield put(getAllKeywordSearchHistorySuccess(newkeywordHistory));
            }
            yield put(notifySuccess("success", "Removed from your history"));
        }
    } catch (error) {
        console.log("ksh error", error);
    }
};

export function* getChatSearchHistory() {
    try {
        const { API_KEYS: { SMP_API_KEY }, API_URL: { GET_CHAT_HISTORY_API } } = CONFIG;
        const hrid = yield call(getUserId);
        const request = {
            method: 'GET',
            endpoint: GET_CHAT_HISTORY_API(hrid),
            headers: {
                'x-api-key': SMP_API_KEY
            }
        };
        const kshResponse = yield call(doAxiosRequest, request);
        if (kshResponse && kshResponse.length > 0) {
            yield put(getChatSearchHistorySuccess(kshResponse));
        } else {
            yield put(getChatSearchHistorySuccess([]));
        }
    } catch (error) {
        console.log("chat history error", error);
    }
};

export function* getChatSearchHistoryByID(action) {
    try {
        const { payload: { urlChatHistoryId, dispatch } } = action;
        const { API_KEYS: { SMP_GENAI_API_KEY }, API_URL: { CHAT_HISTORY_BY_ID_API } } = CONFIG;
        const hrid = yield call(getUserId);
        const request = {
            method: 'POST',
            endpoint: CHAT_HISTORY_BY_ID_API + urlChatHistoryId,
            headers: {
                'x-api-key': SMP_GENAI_API_KEY
            },
            params: {
                requestId: getRandomString(20),
                "consumerId": "EET"
            }
        };
        const chResponse = yield call(doAxiosRequest, request);
        if (chResponse && chResponse.length > 0) {
            yield chResponse.forEach(async (ch, index) => {
                let processedMessage;

                ch.content = ch.content.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

                if (ch.role === ROLES.ASSISTANT) {
                    processedMessage = processMessage(ch, index - 1)
                }
                dispatch({
                    type: CHAT_ADD_MESSAGE, payload: {
                        index,
                        ...ch,
                        ...processedMessage
                    }
                });
            })
            dispatch({ type: CHAT_SET_HISTORY_ID, payload: { response: urlChatHistoryId } });
        }
    } catch (error) {
        console.log("chat history error", error);
    }
};

export function* deleteChatSearchHistory(action) {
    try {
        yield put(setBookmarkPending());
        const { payload: { ch: { id }, chatHistory } } = action;
        const { API_KEYS: { SMP_API_KEY }, API_URL: { DELETE_CHAT_HISTORY_API } } = CONFIG;
        const hrid = yield call(getUserId);
        const request = {
            method: 'DELETE',
            endpoint: DELETE_CHAT_HISTORY_API(hrid, id),
            headers: {
                'x-api-key': SMP_API_KEY
            }
        };
        const chResponse = yield call(doAxiosRequest, request);
        if (chResponse === "Chat History deleted successfully!") {
            if (chatHistory && chatHistory.length > 0) {
                const newChatHistory = chatHistory.filter((kh) => kh.id !== id);
                yield put(getChatSearchHistorySuccess(newChatHistory));
            }
            yield put(notifySuccess("success", "Removed from your history"));
        }
    } catch (error) {
        console.log("delete chat history error", error);
    }
};

export default function* ResponseSaga() {
    try {
        yield takeEvery(QUERY_RESPONSE, getQueryResonse);
        yield takeLatest(GET_FILE, getFile);
        yield takeLatest(GET_SELECTED_TRANSCRIPT, getSelectedTranscript);
        yield takeLatest(GET_DOCVIZ_REQUEST_IDS, getDocvizRequestIds);
        yield takeLatest(GET_DOWNLOAD_COUNT, getDownloadCount);
        yield takeLatest(GET_TRANSCRIPT_DETAIL, getTranscriptDetailFromDB);
        yield takeLatest(QUERY_CHAT_API, queryChatAPI);
        yield takeLatest(SET_BOOKMARK, setBookmark);
        yield takeLatest(DELETE_BOOKMARK, deleteBookmark);
        yield takeLatest(GET_ALL_BOOKMARK, getBookmarks);
        yield takeLatest(SEARCH_BOOKMARK, searchBookmarks);
        yield takeLatest(DELETE_KEYWORD_SEARCH_HISTORY, deleteKeyworSearchHistory);
        yield takeLatest(GET_ALL_KEYWORD_SEARCH_HISTORY, getKeywordSearchHistory);
        yield takeLatest(DELETE_CHAT_SEARCH_HISTORY, deleteChatSearchHistory);
        yield takeLatest(GET_CHAT_SEARCH_HISTORY, getChatSearchHistory);
        yield takeLatest(GET_CHAT_SEARCH_HISTORY_BY_ID, getChatSearchHistoryByID);
    } catch (error) {
        yield put(moduleFailure(error, 'ResponseSaga'));
    }
}

export function* queryChatAPI(action) {
    const { payload: {
        messages,
        selectedEngine,
        chatHistoryId,
        dispatch,
        handleScrollToActiveMessage,
        pageReload
    } } = action;
    yield call(streamChat, messages, selectedEngine, dispatch, chatHistoryId, handleScrollToActiveMessage, pageReload);
}
const streamChat = async (streamMessages, selectedEngine, dispatch, chatHistoryId, onChunk, pageReload, retry = false) => {
    const { API_URL: { CHAT_API, SAVE_CHAT_HISTORY_API }, API_KEYS: { SMP_GENAI_API_KEY, SMP_API_KEY } } = CONFIG;
    dispatch({ type: CHAT_PENDING });
    if (!streamMessages || streamMessages?.length === 0) {
        console.error('callChat: No messages to send');
        return null;
    }
    const _messages = streamMessages.filter(m => !!m.content && (m.role === ROLES.USER || m.role === ROLES.ASSISTANT)).map(message => {
        return {
            role: message.role,
            content: message.originalContent ? message.originalContent : message.content
        };
    });
    const _query = streamMessages.filter(m => !!m.content && m.role === ROLES.USER).pop().content;
    const lastMessageIndex = streamMessages.length;
    const accessToken = await authService.getAccessToken();
    const headers = new Headers();
    headers.append('accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('x-api-key', SMP_GENAI_API_KEY);
    const requestId = getRandomString(20);
    const body_json = {
        "gen_options": {
            "stream": true
        },
        "request_id": requestId,
        "consumer_id": "EET",
        "engine": selectedEngine
    };
    if (chatHistoryId) {
        console.log('EET callChat: Using chatHistoryId', chatHistoryId);
        body_json.chat_history_id = chatHistoryId;
    }
    if (_query && _query.length > 0 && CHAT_API.includes('/v2/')) {
        body_json.query = _query;
    }
    else {
        body_json.messages = _messages;
    }
    const body = JSON.stringify(body_json);
    const fetchController = new AbortController();
    const requestOptions = {
        method: 'POST',
        headers,
        body,
        redirect: 'follow',
        signal: fetchController.signal
    };

    dispatch({ type: SET_FETCH_CONTROLLER, payload: fetchController });

    const startTime = new Date();

    try {
        const response = await fetch(CHAT_API, requestOptions);
        const reader = response.body.pipeThrough(new window.TextDecoderStream()).getReader();


        const sourceLength = '%%sources%%'.length;
        let sourceLoopCount = 0;
        let sourcesTag = '';

        let boldMarkdown = '';
        let boldMarkdownOpen = false;
        let waitForBoldMarkdown = false;

        let contentCnt = 0;
        let chunkCnt = 0;

        let chatResponse = {
            role: ROLES.ASSISTANT,
            content: ''
        };

        let waitForSource = false;
        let endedCleanly = false;
        let createdNewMessage = false;
        let shouldProcessMessage = true;

        let chatId = '';
        let processChunks = true;
        let question;
        while (processChunks) {
            const { value, done } = await reader.read();
            if (done) {
                console.warn(`EETL done with ${contentCnt} chunks ${new Date() - startTime}ms`);
                processChunks = false;
                break;
            }
            if (value) {
                // expected format of value is "data: value\n\ndata: value\n\ndata: value"
                const chunks = value.split('\n\n').filter(c => c);

                chunks.forEach(async (chunk) => {
                    chunkCnt++;
                    let content = null;
                    // expected format of chunk is "data: value"
                    const data = chunk.replace('data:', '').trim();
                    let json = {};
                    try {
                        json = data ? JSON.parse(data) : {};
                        // we receive many chunks, but only chunks with choices[0].delta.content are chat messages
                        content = json?.choices?.length > 0 ? json.choices[0].delta?.content : null;
                        if (!chatId && json?.id) {
                            chatId = json.id;
                        }
                    } catch (ex) {
                        console.warn(`EETL failed to parse json. Attempting regex. error:${ex}; data:${data}; value: ${value}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);
                        // attempt to get content using regex
                        const matches = data.match(/"content":\s*"([^"]*)"/i);
                        if (matches?.length >= 1) {
                            content = matches[1];
                        } else {
                            if (retry) {
                                throw new Error(`EETL Second attempt failed to find content in chunk. error:${ex}; data:${data}; matches:${matches}; value: ${value}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);
                            } else {
                                console.error(`EETL failed to find content in chunk. Retrying. error:${ex}; data:${data}; matches:${matches}; value: ${value}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);

                                // stop this API call and let's try again
                                content = null;
                                processChunks = false;
                                endedCleanly = true; // yes really, if false it'll trigger an error which isnt accurate
                                shouldProcessMessage = false;
                                dispatch({ type: CHAT_DELETE_MESSAGE, payload: { index: lastMessageIndex } });
                                dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { content: 'We\'ve experienced an error, we\'re trying again.', role: ROLES.STATUS } });
                                console.log('EETL run again');
                                fetchController.abort('trying again');
                                return dispatch(streamChat(streamMessages, selectedEngine, dispatch, pageReload, onChunk, true));
                            }
                        }
                    }
                    // now work with the data in json object
                    // render to user

                    if (content) {
                        if (contentCnt === 0) {
                            console.warn(`EETL time to first chunk ${new Date() - startTime}ms chatId: ${chatId};`);
                        }
                        let newContent = content;
                        newContent = newContent.replace(/\n/g, '<br/>');
                        if (newContent.match(/\*{1,2}/ig)) {
                            // has a *, is it mardown for bold **
                            boldMarkdown += content;
                            waitForBoldMarkdown = true;
                        } else if (waitForBoldMarkdown) {
                            boldMarkdown += content;
                            if (boldMarkdown.match(/\*\*/ig)) {
                                if (!boldMarkdownOpen) {
                                    newContent = boldMarkdown.replace(/\*\*/ig, '<b>');
                                    boldMarkdownOpen = true;
                                }
                                else {
                                    newContent = boldMarkdown.replace(/\*\*/ig, '</b>');
                                    boldMarkdownOpen = false;
                                }
                                waitForBoldMarkdown = false;
                                boldMarkdown = '';
                            }
                        }

                        if (!waitForBoldMarkdown) {
                            if (!createdNewMessage) {
                                dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { role: ROLES.ASSISTANT, content: newContent, index: lastMessageIndex } });
                                createdNewMessage = true;
                            } else {
                                dispatch({ type: CHAT_STREAM_CHUNK, payload: { content: newContent, lastMessageIndex } });
                                onChunk && onChunk();
                            }
                            chatResponse.content += newContent;
                            //console.log('EETL added', content);
                        }

                        contentCnt++;

                        if (question) {
                            dispatch({
                                type: SET_LATEST_SEARCH_TEXT, payload: {
                                    response: _query
                                }
                            });
                            // dispatch({
                            //     type: QUERY_RESPONSE, payload: {
                            //         question: _query, resetFilters: true, dispatch
                            //     }
                            // });
                            question = undefined;
                        }
                    }
                    if (json?.user_message) {
                        console.log(`EETL message: ${json.user_message}; chatId: ${chatId};`);
                        dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { content: json.user_message, role: ROLES.STATUS } });
                        const user_message = json.user_message;
                        question = user_message.substring(user_message.indexOf('for "') + 5, user_message.length - 1);
                        onChunk && onChunk();
                    }
                    if (json?.system_message) {
                        switch (json.system_message) {
                            case 'usage':
                                console.warn(`EETL usage: ${json.usage}; chatId: ${chatId};`);
                                break;
                            case 'END CHAT':
                                processChunks = false;
                                endedCleanly = true;
                                dispatch({ type: CHAT_SET_HISTORY_ID, payload: { response: json.chat_history_id } });
                                // Start Save Chat History Search
                                if (pageReload && !chatHistoryId) {
                                    const hrid = await getUserId();
                                    const chRequest = {
                                        method: 'POST',
                                        endpoint: SAVE_CHAT_HISTORY_API,
                                        headers: {
                                            'x-api-key': SMP_API_KEY
                                        },
                                        params: {
                                            "hrId": hrid,
                                            "firstMessage": _query.substring(0, 255),
                                            "chatHistoryId": json.chat_history_id
                                        }
                                    };
                                    await doAxiosRequest(chRequest);
                                }
                                // End Save Chat History Search
                                break;
                            case 'START INNER CHAT':
                                dispatch({ type: CHAT_SET_HISTORY_ID, payload: { response: json.chat_history_id } });
                                break;
                            default:
                                break;
                        }
                        console.warn(`EETL system message: ${json.system_message}; chatId: ${chatId};`);
                        if (json?.error) {
                            console.error(`EETL system error: ${json.error}; chatId: ${chatId};`);
                            throw new Error(`EETL system error: ${json.error}; chatId: ${chatId};`);
                        }
                    }
                })
            }
        }

        if (!endedCleanly) {
            console.warn(`EETL ended prematurely. chatId: ${chatId}; chunkCnt: ${chunkCnt}; contentCnt: ${contentCnt};`);
        }

        if (shouldProcessMessage) {
            const processedMessage = processMessage(chatResponse, lastMessageIndex);
            dispatch({ type: CHAT_SUCCESS, payload: processedMessage });
        }
    } catch (ex) {
        console.warn(`EETL failed. error:${ex};`);
        dispatch({ type: CHAT_FAILURE, payload: { message: 'Apologies, we\'re experiencing high demand. Please try your request in a few minutes.' } });
    }
}

const processMessage = (message, index) => {
    // console.log('EETL completed. Usage:', data.usage);
    if (message.role === 'function') return null;



    // let sources;


    // return {
    //     ...message,
    //     index: index + 1,
    //     content: _content,
    //     sources,
    //     originalContent: message.content
    // };
    const sources = [];
    const fileNameRegex = /filename=([a-zA-Z0-9\s_#.+/,&()|%-:[\]]*)/g;
    const transcriptIdRegex = /\/transcript\/([a-zA-Z0-9-]+)/g;

    if (message.content.match(transcriptIdRegex)) {
        let matchFileName, matchTranscriptId;
        while ((matchFileName = fileNameRegex.exec(message.content)) && (matchTranscriptId = transcriptIdRegex.exec(message.content))) {
            try {
                const fileName = matchFileName[1];
                let docid = matchTranscriptId[1];

                sources.push({
                    fileName,
                    id: docid,
                });


            } catch (ex) {
                console.error('EET Failed to parse sources in content', ex);
                console.error('EET content', message.content);
                return null;
            }
        }
    }

    const uniqueSources = sources.reduce((acc, current) => acc.some(o => o.id === current.id) ? acc : [...acc, current], []);

    // let _content, delimiter;

    // if (message.content.match(/%%source[s]{0,1}%%/ig)) {
    //     // delimiter is inconsistent from gpt
    //     // so we use either `sources:` and `source:`
    //     delimiter = message.content.match(/%%source[s]{0,1}%%/ig)[0];
    //     const indexOfDelimiter = message.content.indexOf(delimiter);
    //     _content = cleanContentFromChat(message.content.substring(0, indexOfDelimiter));
    // } else {
    //     _content = cleanContentFromChat(message.content);
    // }
    const temp = document.createElement('div');
    temp.innerHTML = message.content;
    var sups = temp.getElementsByTagName('sup');
    for (let i = 0, max = sups.length; i < max; i++) {
        const a = sups[i].getElementsByTagName('a')[0];
        uniqueSources.forEach((source, index) => {
            if (a.getAttribute('href').includes(source.id)) {
                a.innerHTML = index + 1;
                sups[i].className = 'final';
            }
        })
    }
    message.content = temp.innerHTML;
    return {
        ...message,
        index: index + 1,
        sources: uniqueSources
    };
};

const cleanJsonFromChat = (jsonAsString) => {
    return jsonAsString
        .replace(/(\r)|(json)|[`]/gi, '')
        .trim();
};
const cleanContentFromChat = (content) => {
    return content
        .replace(/(\n)/gi, '<br/>')
        .trim();
};