import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { RESPONSE_DATA } from "redux/constants";
import SearchIcon from "assets/images/SearchGreenIcon.svg";
import Bookmark2Icon from "assets/images/bookmark_2.svg";
import WordIcon from "assets/images/word_icon.svg";
import Image from "components/shared/Image";
import { downloadFileFromUrl } from "utils/helpers/helpers";
import { Tooltip } from "components/shared/Tooltip";
import "./Bookmarks.scss";

const {
  GET_ALL_BOOKMARK,
  DELETE_BOOKMARK,
  SEARCH_BOOKMARK
} = RESPONSE_DATA;


export const Bookmarks = () => {
  const dispatch = useDispatch();
  const [searchBookmark, setSearchBookmark] = useState("");
  const bookmarkedTranscripts = useSelector(responseSelector.getBookmarkedTranscripts);
  const isBookmarkAPIPending = useSelector(responseSelector.getBookmarkAPIPending);
  const selectedTrancript = useSelector(responseSelector.getSelectedTranscript);
  const openTranscriptInNewTab = (event, transcript) => {
    event.stopPropagation();
    //sendToLocalStorage("eVar2ForDetailedTranscript", "fromKeywordSearch");
    downloadFileFromUrl(`transcript/${transcript.meetingId}`, "_blank");
  };
  useEffect(() => {
    dispatch({ type: GET_ALL_BOOKMARK, payload: {} });
  }, []);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      if (searchBookmark.length > 2) {
        dispatch({ type: SEARCH_BOOKMARK, payload: { searchBookmark } });
      } else {
        dispatch({ type: GET_ALL_BOOKMARK, payload: {} });
      }
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchBookmark]);
  const unBookmarkFile = (transcript) => {
    dispatch({ type: DELETE_BOOKMARK, payload: { transcript, bookmarkedTranscripts, selectedTrancript } });
  }
  return (
    <div className="bookmarks_container">
      <div className='filter-search-container'>
        <div className="filter-search-box">
          <Image src={SearchIcon} alt="logo" />
          <input value={searchBookmark} onChange={(e) => setSearchBookmark(e.target.value)} placeholder="Search" />
        </div>
      </div>
      <div className="bookmarks">
        {bookmarkedTranscripts.map((transcript) => {
          return (
            <div className="bookmark" key={transcript.id}>
              <div className="bookmark__heading">
                <div className="wordIconContainer">
                  <Image className="wordIcon" src={WordIcon} alt="WordIcon" />
                </div>
                <div data-tip data-for='TranscriptTitle' className="title" onClick={(e) => openTranscriptInNewTab(e, transcript)} title={""}>
                  {transcript.keyword.length > 98 ? transcript.keyword.substring(0, 98) + "..." : transcript.keyword}
                </div>
                <Tooltip id="TranscriptTitle" delayHide={0}>Click to open in new tab</Tooltip>
              </div>
              <div className="bookmark__icon">
                <Image data-tip data-for='DeleteBookmark' className="icon" src={Bookmark2Icon} alt="logo" onClick={() => unBookmarkFile(transcript)} />
                <Tooltip id="DeleteBookmark" delayHide={0}>Delete Bookmark</Tooltip>
              </div>

            </div>
          );
        })}
      </div>
      {
        bookmarkedTranscripts.length === 0 &&
        <div className="no-bookmarks-container">
          <div className="first-line">{searchBookmark.length > 2 ? "No bookmarks found for your search" : "No bookmarks yet"}</div>
          {searchBookmark.length <= 2 && <div className="second-line">Bookmark transcripts to see them here</div>}
        </div>
      }
    </div >
  );
}



