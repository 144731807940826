import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { RESPONSE_DATA } from "redux/constants";
import OpenInNewTabGreen from "assets/images/open_in_new_tab_green.svg";
import DeleteIcon from "assets/images/delete_icon_green.svg";
import FilterIcon from "assets/images/history_filter_icon.svg";
import Image from "components/shared/Image";
import { downloadFileFromUrl } from "utils/helpers/helpers";
import { Tooltip } from "components/shared/Tooltip";
import "./History.scss";

const {
  GET_ALL_KEYWORD_SEARCH_HISTORY,
  DELETE_KEYWORD_SEARCH_HISTORY,
  GET_CHAT_SEARCH_HISTORY,
  DELETE_CHAT_SEARCH_HISTORY,
} = RESPONSE_DATA;


export const History = () => {
  const dispatch = useDispatch();
  const [showKeywordHistory, setShowKeywordHistory] = useState(true);
  const keywordHistory = useSelector(responseSelector.getKeywordHistory);
  const chatHistory = useSelector(responseSelector.getChatHistory);
  const openKHInNewTab = (event, kh) => {
    event.stopPropagation();
    downloadFileFromUrl(`/?query=${kh.searchTerm}`, "_blank");
  };
  const openCHInNewTab = (event, ch) => {
    event.stopPropagation();
    downloadFileFromUrl(`/chat/?query=${ch.firstMessage}&chatHistoryId=${ch.chatHistoryId}`, "_blank");
  };
  const historyCategories = [{
    title: "Today",
    items: [],
  }, {
    title: "Yesterday",
    items: [],
  }, {
    title: "Last 7 Days",
    items: [],
  }, {
    title: "Older",
    items: [],
  }];
  const chatHistoryCategories = [{
    title: "Today",
    items: [],
  }, {
    title: "Yesterday",
    items: [],
  }, {
    title: "Last 7 Days",
    items: [],
  }, {
    title: "Older",
    items: [],
  }];

  keywordHistory.forEach(kh => {
    const khDate = kh.createdDatetime.substring(0, 10);
    let todayDate = new Date();
    const today = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1 < 10 ? "0" + (todayDate.getMonth() + 1) : todayDate.getMonth() + 1}-${todayDate.getDate() < 10 ? "0" + todayDate.getDate() : todayDate.getDate()}`;
    if (khDate === today) {
      historyCategories[0].items.push(kh);
    } else {
      const yesterdayDate = new Date(todayDate.setDate(todayDate.getDate() - 1));
      const yesterday = `${yesterdayDate.getFullYear()}-${yesterdayDate.getMonth() + 1 < 10 ? "0" + (yesterdayDate.getMonth() + 1) : yesterdayDate.getMonth() + 1}-${yesterdayDate.getDate() < 10 ? "0" + yesterdayDate.getDate() : yesterdayDate.getDate()}`;
      if (khDate === yesterday) {
        historyCategories[1].items.push(kh);
      } else {
        const minus7DaysDate = new Date(todayDate.setDate(todayDate.getDate() - 7));
        const minus7Days = `${minus7DaysDate.getFullYear()}-${minus7DaysDate.getMonth() + 1 < 10 ? "0" + (minus7DaysDate.getMonth() + 1) : minus7DaysDate.getMonth() + 1}-${minus7DaysDate.getDate() < 10 ? "0" + minus7DaysDate.getDate() : minus7DaysDate.getDate()}`;
        if (khDate >= minus7Days) {
          historyCategories[2].items.push(kh);
        } else {
          historyCategories[3].items.push(kh);
        }
      }
    }
  })

  chatHistory.forEach(kh => {
    const khDate = kh.createdDatetime.substring(0, 10);
    let todayDate = new Date();
    const today = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1 < 10 ? "0" + (todayDate.getMonth() + 1) : todayDate.getMonth() + 1}-${todayDate.getDate() < 10 ? "0" + todayDate.getDate() : todayDate.getDate()}`;
    if (khDate === today) {
      chatHistoryCategories[0].items.push(kh);
    } else {
      const yesterdayDate = new Date(todayDate.setDate(todayDate.getDate() - 1));
      const yesterday = `${yesterdayDate.getFullYear()}-${yesterdayDate.getMonth() + 1 < 10 ? "0" + (yesterdayDate.getMonth() + 1) : yesterdayDate.getMonth() + 1}-${yesterdayDate.getDate() < 10 ? "0" + yesterdayDate.getDate() : yesterdayDate.getDate()}`;
      if (khDate === yesterday) {
        chatHistoryCategories[1].items.push(kh);
      } else {
        const minus7DaysDate = new Date(todayDate.setDate(todayDate.getDate() - 7));
        const minus7Days = `${minus7DaysDate.getFullYear()}-${minus7DaysDate.getMonth() + 1 < 10 ? "0" + (minus7DaysDate.getMonth() + 1) : minus7DaysDate.getMonth() + 1}-${minus7DaysDate.getDate() < 10 ? "0" + minus7DaysDate.getDate() : minus7DaysDate.getDate()}`;
        if (khDate >= minus7Days) {
          chatHistoryCategories[2].items.push(kh);
        } else {
          chatHistoryCategories[3].items.push(kh);
        }
      }
    }
  })
  useEffect(() => {
    dispatch({ type: GET_ALL_KEYWORD_SEARCH_HISTORY, payload: {} });
    dispatch({ type: GET_CHAT_SEARCH_HISTORY, payload: {} });
  }, []);
  const deleteKH = (kh) => {
    dispatch({ type: DELETE_KEYWORD_SEARCH_HISTORY, payload: { kh, keywordHistory } });
  }
  const deleteCH = (ch) => {
    dispatch({ type: DELETE_CHAT_SEARCH_HISTORY, payload: { ch, chatHistory } });
  }
  return (
    <div className="ksh_container">
      <div className="filter-pills">
        <div><Image className="filter-icon" src={FilterIcon} alt="FilterIcon" /></div>
        <div className={`h-pill ${showKeywordHistory && 'active'}`} onClick={() => setShowKeywordHistory(true)}>
          <div className="h-pill-title">Keyword Search</div>
        </div>
        <div className={`h-pill ${!showKeywordHistory && 'active'}`} onClick={() => setShowKeywordHistory(false)}>
          <div className="h-pill-title">Chat Search</div>
        </div>
      </div>
      {showKeywordHistory ? <><div className="kshs">
        {historyCategories.map((hc) => {
          return hc.items.length > 0 && (
            <div className="hc" key={hc.title}>
              <div className="hc-title">{hc.title}</div>
              {hc.items.map((kh) => {
                return (
                  <div className="ksh" key={kh.id}>
                    <div className="ksh__heading">
                      <div data-tip data-for={`HistoryTitle${kh.id}`} className="title" onClick={(e) => openKHInNewTab(e, kh)}>
                        {kh.searchTerm.length > 50 ? kh.searchTerm.substring(0, 50) + "..." : kh.searchTerm}
                      </div>
                      <Tooltip id={`HistoryTitle${kh.id}`} delayHide={0}>Click to open in new tab</Tooltip>
                    </div>
                    <div className="ksh__icon">
                      {/* <Image data-tip data-for={`OpenInNewTabKH${kh.id}`} className="icon" src={OpenInNewTabGreen} alt="logo" onClick={(e) => openKHInNewTab(e, kh)} />
                      <Tooltip id={`OpenInNewTabKH${kh.id}`} position="left" delayHide={0}>Open in new tab</Tooltip> */}
                      <Image data-tip data-for={`DeleteKH${kh.id}`} className="icon" src={DeleteIcon} alt="logo" onClick={() => deleteKH(kh)} />
                      <Tooltip id={`DeleteKH${kh.id}`} position="left" delayHide={0}>Delete</Tooltip>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
        {
          keywordHistory.length === 0 &&
          <div className="no-history-container">
            <div className="first-line">No history yet</div>
            <div className="second-line">View your activity here once you have some</div>
          </div>
        }
      </> :
        <><div className="kshs">
          {chatHistoryCategories.map((hc) => {
            return hc.items.length > 0 && (
              <div className="hc" key={hc.title}>
                <div className="hc-title">{hc.title}</div>
                {hc.items.map((kh) => {
                  return (
                    <div className="ksh" key={kh.id}>
                      <div className="ksh__heading">
                        <div data-tip data-for={`HistoryTitle${kh.id}`} className="title" onClick={(e) => openCHInNewTab(e, kh)}>
                          {kh.firstMessage.length > 50 ? kh.firstMessage.substring(0, 50) + "..." : kh.firstMessage}
                        </div>
                        <Tooltip id={`HistoryTitle${kh.id}`} delayHide={0}>Click to open in new tab</Tooltip>
                      </div>
                      <div className="ksh__icon">
                        {/* <Image data-tip data-for={`OpenInNewTabKH${kh.id}`} className="icon" src={OpenInNewTabGreen} alt="logo" onClick={(e) => openKHInNewTab(e, kh)} />
                    <Tooltip id={`OpenInNewTabKH${kh.id}`} position="left" delayHide={0}>Open in new tab</Tooltip> */}
                        <Image data-tip data-for={`DeleteKH${kh.id}`} className="icon" src={DeleteIcon} alt="logo" onClick={() => deleteCH(kh)} />
                        <Tooltip id={`DeleteKH${kh.id}`} position="left" delayHide={0}>Delete</Tooltip>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
          {
            chatHistory.length === 0 &&
            <div className="no-history-container">
              <div className="first-line">No history yet</div>
              <div className="second-line">View your activity here once you have some</div>
            </div>
          }
        </>
      }
    </div >
  );
}



